import type { ServerColumnRow, ServerDataRow } from '~/types/component'

export function formatColumnData(value: string | number, dataType: string | undefined | null = undefined) {
  let formattedValue = value

  if (!dataType) {
    return formattedValue
  }
  // formatting the columns

  if (dataType?.toLowerCase() === 'decimal' || dataType?.toLowerCase() === 'integer') {
    formattedValue = 0
    if (value) {
      formattedValue = getNumberValue(value)
    }
  }

  if (dataType.toLowerCase() === 'percent') {
    formattedValue = 0
    if (value) {
      formattedValue = parseFloat((getNumberValue(value) * 100).toFixed(2))
    }
  }

  return formattedValue
}

export default function (
  columns: Array<ServerColumnRow>,
  data: Array<Array<ServerDataRow>>,
  ignoredColumns?: Array<number>,
  mapRowColumnFunction?: (row: ServerDataRow) => unknown
): Array<{ [name: string]: unknown }> {
  const mapRowColumn: (row: ServerDataRow) => unknown
    = mapRowColumnFunction
    || ((row: ServerDataRow) => {
      if (['string'].includes(row.type)) {
        return row.formattedValue
      }

      if (['decimal'].includes(row.type)) {
        return row.rawValue || row.value
      }

      return row.value
    })

  const flattenedData = data.map(row => row.map(row => mapRowColumn(row)))
  const flat = flattenedData.map(a =>
    a.reduce((previousValue, currentValue, i) => {
      if (ignoredColumns?.includes(i)) {
        return previousValue
      }

      const header = columns[i].dimensionCaption

      previousValue[header] = formatColumnData(currentValue as string | number, columns[i].columnDataType)
      return previousValue
    }, {})
  )

  return flat
}

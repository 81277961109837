import dayjs, { type ManipulateType } from 'dayjs'
import type { HubFilter } from '~/types'

const now = dayjs()

// functions
export function formatQueryParamsForAPI(
  filters:
    | {
      [name: string]: string | string[] | number | undefined
    }
    | HubFilter
): { [name: string]: string | string[] | number | undefined } {
  // Handle relative dates before they hit the API
  const variablesForAPI: {
    [name: string]: string | string[] | number | undefined
  } = {
    ...filters
  }

  // remove touchpointIds and hash properties as these are for the UI only
  if (Object.prototype.hasOwnProperty.call(variablesForAPI, 'touchpointIds')
    || Object.prototype.hasOwnProperty.call(variablesForAPI, 'hash')) {
    delete variablesForAPI.touchpointIds
    delete variablesForAPI.hash
  }

  // re-format relative dates into a dateFrom and dateTo, & then remove the relativeDate property
  if (
    Object.prototype.hasOwnProperty.call(variablesForAPI, 'relativeDate')
    && typeof variablesForAPI.relativeDate === 'string'
  ) {
    variablesForAPI.dateFrom = undefined
    variablesForAPI.dateTo = undefined

    // @ts-expect-error we set this, so we know what it is
    const relativeDate: ['previous' | 'next', string, ManipulateType] = variablesForAPI.relativeDate.split('|')

    if (relativeDate[0] === 'previous') {
      variablesForAPI.dateFrom = now.subtract(parseInt(relativeDate[1]), relativeDate[2]).format('YYYY-MM-DD')
    } else {
      variablesForAPI.dateFrom = now.add(parseInt(relativeDate[1]), relativeDate[2]).format('YYYY-MM-DD')
    }

    if (
      relativeDate[0] === 'previous'
      && ((relativeDate[1] === '24' && relativeDate[2] === 'hour')
      || (relativeDate[1] === '7' && relativeDate[2] === 'day'))
    ) {
      variablesForAPI.dateTo = now.subtract(1, 'day').format('YYYY-MM-DD')
    } else {
      variablesForAPI.dateTo = now.format('YYYY-MM-DD')
    }
    variablesForAPI.relativeDate = undefined
  }

  // remove any undefined values
  Object.keys(variablesForAPI).forEach(
    key => {
      if (variablesForAPI[key] === undefined || variablesForAPI[key] === '') {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete variablesForAPI[key]
      }
    }
  )

  return variablesForAPI
}

import type { ServerColumnRow, ServerDataResponse, ServerDataRow } from '~/types/component'
import { sentimentColors, CustomError } from '~/types/app'

export default function (reportData: ServerDataResponse | undefined | null, type: 'list' | 'mention' | 'sentiment') {
  if (!reportData) {
    return reportData
  }

  const columns: Array<ServerColumnRow> = reportData.results.columnHeadings
  const data: Array<Array<ServerDataRow>> = reportData.results.rows

  let parsedData

  switch (type) {
    case 'list': {
      const comments = flattenData(columns, data, undefined, (row: ServerDataRow) => ({
        value: row.value,
        formattedValue: row.formattedValue
      }))

      parsedData = { comments }

      if (!parsedData.comments.length) {
        throw new CustomError('reports.errors.comments.header', 'reports.errors.generic.message.selectDifferentFilters')
      }

      break
    }
    case 'mention':
      parsedData = { mentions: flattenData(columns, data) }
      break
    case 'sentiment': {
      const flattenedData = flattenData(columns, data, undefined, (row: ServerDataRow) => ({
        rawValue: row.rawValue,
        value: row.value,
        formattedValue: row.formattedValue
      })) as Array<{
        ParentQuestionCategory: {
          value: string
        }
        QuestionCategory: {
          value: string
          formattedValue: string
          rawValue: string
        }
        CommentQuantity: {
          value: string
          rawValue: string
        }
      }>

      const filteredData = flattenedData.filter(x => x.ParentQuestionCategory.value === 'Sentiment')

      parsedData = {
        sentiments: filteredData.map(row => {
          const color
            = sentimentColors[row.QuestionCategory.rawValue.toLowerCase() as keyof typeof sentimentColors]

          return {
            Sentiment: row.QuestionCategory.formattedValue,
            SentimentName: row.QuestionCategory.value,
            SentimentValue: row.CommentQuantity.rawValue,
            SentimentColor: color
          }
        })
      }

      if (!parsedData.sentiments.some(x => getNumberValue(x.SentimentValue) > 0)) {
        throw new Error('No Data Found')
      }

      break
    }
    default:
      throw new Error('Invalid Parse Type')
  }

  return parsedData
}
